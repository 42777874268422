<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">姓名</label>
        <el-input
          v-model="searchName"
          placeholder="请输入姓名"
          autocomplete="off"
          size="small"
          style="width: 100px"
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchSchoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">手机</label>
        <el-input
          v-model.trim="searchTel"
          placeholder="请输入手机号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">身份证</label>
        <el-input
          v-model.trim="searchIdNumber"
          placeholder="请输入身份证"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">加密学生姓名</label>
        <el-input
          v-model="searchCjmXm"
          placeholder="请输入加密学生姓名"
          autocomplete="off"
          size="small"
          style="width: 150px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">加密身份证</label>
        <el-input
          v-model="searchcjmZjhm"
          placeholder="请输入加密身份证"
          autocomplete="off"
          size="small"
          style="width: 150px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集码</label>
        <el-input
          v-model="searchCjmn"
          placeholder="请输入采集码"
          autocomplete="off"
          size="small"
          style="width: 150px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学号</label>
        <el-input
          v-model="studentCode"
          placeholder="请输入学号"
          autocomplete="off"
          size="small"
          style="width: 150px"
          clearable
        >
        </el-input>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
        <el-button
          v-if="addButton"
          type="success"
          icon="el-icon-plus"
          @click="handleAdd"
          size="small"
          >新增
        </el-button>
        <el-button
          v-if="deleteButton"
          type="danger"
          icon="el-icon-delete"
          @click="handleDelete"
          size="small"
          >删除
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="studentList"
        stripe
        border
        height="100%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <p>{{ tableStudentLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column fixed type="selection" width="50"></el-table-column>
        <el-table-column prop="name" label="姓名" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span></template
          >
        </el-table-column>
        <el-table-column prop="sex" label="性别" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.sex === "1" ? "男" : "女" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="idNumber"
          label="证件号"
          width="260"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.idNumber, "Idcard")
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="wxNumber"
          label="手机号"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.wxNumber, "ph")
            }}</span></template
          >
        </el-table-column>
        <el-table-column prop="schoolName" label="学校" align="center">
        </el-table-column>
        <el-table-column label="操作" width="360" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="updateButton"
              type="text"
              @click="handleUpdate(scope.row.id)"
            >
              修改
            </el-button>
            <el-button
              v-if="educationButton"
              type="text"
              @click="handleEducation(scope.row.id)"
            >
              学历管理
            </el-button>
            <el-button
              v-if="viewButton"
              type="text"
              @click="handleView(scope.row.id)"
            >
              查看
            </el-button>
            <el-button
              v-if="queryStuLog"
              type="text"
              @click="handleStuLogView(scope.row)"
            >
              查看学生日志
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="dialogType === 1 ? '新增' : dialogType === 2 ? '查看' : '修改'"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="studentForm"
        :model="studentForm"
        label-width="100px"
        :rules="studentRules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input
                type="text"
                v-model="studentForm.name"
                placeholder="请输入姓名"
                :disabled="dialogType === 2"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-radio-group
                v-model="studentForm.sex"
                :disabled="dialogType === 2"
              >
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="证件号">
              <el-input
                type="text"
                v-model="studentForm.idNumber"
                placeholder="请输入证件号"
                :disabled="dialogType === 2"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出生日期" prop="birthday">
              <el-date-picker
                v-model="studentForm.birthday"
                type="date"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择出生日期"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="民族" prop="nation">
              <el-select
                v-model="studentForm.nation"
                placeholder="请选择民族"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
                <el-option
                  v-for="item in nationOptions"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobileNumber">
              <el-input
                type="text"
                v-model="studentForm.mobileNumber"
                placeholder="请输入手机号"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-switch
                v-model="studentForm.status"
                :active-value="1"
                :inactive-value="0"
                :disabled="dialogType === 2"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="默认邮寄信息" prop="mailingAdress">
              <el-input
                type="text"
                v-model="studentForm.mailingAdress"
                placeholder="请输入默认邮寄信息"
                :disabled="dialogType === 2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogVisible = false" style="margin-right: 20px"
          >取 消
        </el-button>
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleConfirm"
          :loading="confirmLoading"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="学历管理"
      :visible.sync="dlgEduVisible"
      width="900px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-row>
        <el-col :span="24" style="margin-bottom: 20px; text-align: right">
          <el-button
            type="primary"
            size="small"
            class="el-icon--right"
            icon="el-icon-circle-plus"
            @click="handleAddEducation()"
            >新增
          </el-button>
        </el-col>
      </el-row>
      <el-table
        :data="educationList"
        stripe
        border
        height="200"
        style="width: 100%"
      >
        <el-table-column
          prop="schoolName"
          label="学校"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="educationType"
          label="学历"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="schoolArea"
          label="校区"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="schoolDept"
          label="院系"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="major" label="所学专业" align="center">
        </el-table-column>
        <el-table-column prop="classes" label="班级" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="studentCode"
          label="学号"
          width="120"
          align="center"
        >
        </el-table-column>
        <!--                <el-table-column prop="examineeNumber" label="考生号" width="80">-->
        <!--                </el-table-column>-->
        <el-table-column prop="isImport" label="是否导入" width="80">
          <template slot-scope="scope">
            {{ scope.row.isImport === 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdateEducation(scope.row.id)">
              修改
            </el-button>
            <el-button type="text" @click="handleDeleteEducation(scope.row.id)">
              删除
            </el-button>
            <el-button type="text" @click="handleEduShow(scope.row.id)">
              查看学历日志
            </el-button>
            <el-button type="text" @click="handleUnbinding(scope.row.id)">
              解绑学历
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-form
        class="divForm"
        ref="educationForm"
        :model="educationForm"
        label-width="100px"
        :rules="educationRules"
      >
        <el-row>
          <el-col :span="24" style="text-align: left">
            <el-input
              disabled="disabled"
              v-show="false"
              type="text"
              v-model="educationForm.studentId"
            ></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                style="width: 100%"
                v-model="educationForm.schoolId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入学校名或代码检索"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in schools"
                  :key="item.id"
                  :label="item.schoolFilterName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历类别" prop="educationType">
              <el-select
                v-model="educationForm.educationType"
                placeholder="请选择学历"
                style="width: 100%"
              >
                <el-option
                  v-for="item in educationTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学校类别" prop="schoolType">
              <el-select
                v-model="educationForm.schoolType"
                placeholder="请选择学校类别"
                :disabled="dialogType === 2"
                style="width: 100%"
              >
                <el-option
                  v-for="item in schoolTypeOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="校区" prop="schoolArea">
              <el-input
                type="text"
                v-model="educationForm.schoolArea"
                placeholder="请输入校区"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="院系" prop="schoolDept">
              <el-input
                type="text"
                v-model="educationForm.schoolDept"
                placeholder="请输入院系"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所学专业" prop="major">
              <el-input
                type="text"
                v-model="educationForm.major"
                placeholder="请输入所学专业"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="班级" prop="classes">
              <el-input
                type="text"
                v-model="educationForm.classes"
                placeholder="请输入班级"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="studentCode">
              <el-input
                type="text"
                v-model="educationForm.studentCode"
                placeholder="请输入学号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="考生号" prop="examineeNumber">
              <el-input
                type="text"
                v-model="educationForm.examineeNumber"
                placeholder="请输入考生号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-switch
                v-model="educationForm.status"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgEduVisible = false" style="margin-right: 20px"
          >取 消
        </el-button>
        <el-button
          type="primary"
          @click="handleSaveTime"
          :loading="confirmLoading"
          >保存
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="批量导入"
      :visible.sync="dlgImportVisible"
      width="70%"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :rules="importRules"
        :model="fileForm"
        ref="fileForm"
        label-width="150px"
        @submit.native.prevent
      >
        <el-form-item label="文件：" prop="file">
          <el-upload
            class="upload-demo"
            ref="uploadImport"
            :multiple="false"
            action=""
            :limit="1"
            :on-change="handleChange"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept=".xls,.xlsx"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件并导入
            </el-button>
            <!--            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">导入</el-button>-->
            <div slot="tip" class="el-upload__tip">
              只能上传xls/xlsx文件，且不超过10M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="日志" prop="importMsg">
          <el-alert
            :visible.sync="msgVisible"
            v-model="importMsg"
            :title="importMsg"
            show-icon
            :type="importSatus"
          >
          </el-alert>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgImportVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 订单日志 -->
    <el-dialog
      title="学生信息日志"
      :visible.sync="showStuLogs"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table
        :data="stuListLog"
        stripe
        border
        height="400"
        style="width: 100%"
      >
        <el-table-column
          prop="username"
          label="操作人"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="状态"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="note" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 学历信息 -->
    <el-dialog
      title="学历信息日志"
      :visible.sync="showEdu"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table
        :data="eduListLogs"
        stripe
        border
        height="400"
        style="width: 100%"
      >
        <el-table-column
          prop="username"
          label="操作人"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="状态"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="note" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>
    <studentInfoFrom ref="studentInfo" />
  </div>
</template>

<script>
import {
  queryStudent,
  addStudent,
  getStudentForm,
  updateStudent,
  deleteStudent,
  getEducations,
  addEducation,
  getEducation,
  updateEducation,
  deleteEducation,
  importStudent,
  getStudentLogsList,
  getStudentEduLogsList,
  unbind,
} from "@/api/student";
import { queryOrderByEducationId } from "@/api/order";
import { dateFormat } from "@/utils/date";
import { getSchoolFiller } from "@/api/school";
import { getDict } from "@/api/dict";
import { queryPageButton } from "@/api/permission";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import studentInfoFrom from "@/components/studentInfoFrom.vue";

export default {
  name: "studentInfoMgt",
  components: {
    studentInfoFrom,//查看详情
  },
  data() {
    var res =
      /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    var telres = /^1[0-9]{10}$/;
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("姓名不能为空"));
      } else {
        callback();
      }
    };
    const validateSex = (rule, value, callback) => {
      if (value != 1 && value != 2) {
        callback(new Error("性别不能为空"));
      } else {
        callback();
      }
    };
    // const validateIdNumber = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("证件号不能为空"));
    //   } else {
    //     if (res.test(value) == false) {
    //       callback(new Error("身份证格式不正确"));
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    const validateMobileNumber = (rule, value, callback) => {
      if (!value) {
        //callback(new Error("手机号不能为空"));
        callback();
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的11位数"));
      } else {
        if (telres.test(value) == false) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };

    const validateSchoolId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校不能为空"));
      } else {
        callback();
      }
    };
    const validateEducationType = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学历不能为空"));
      } else {
        callback();
      }
    };
    const validateStudentCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学号不能为空"));
      } else {
        callback();
      }
    };

    return {
      studentId: 0,
      searchName: "",
      searchTel: "",
      searchIdNumber: "",
      searchCjmXm: "",
      searchcjmZjhm: "",
      searchCjmn: "",
      studentCode: "",
      searchSchoolId: null,

      studentList: [],
      educationList: [],
      tableStudentLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,

      dialogType: 1,
      dialogVisible: false,
      studentForm: {},
      studentRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        sex: [{ required: true, trigger: "blur", validator: validateSex }],
        // idNumber: [{ required: true, trigger: "blur", validator: validateIdNumber }],
        mobileNumber: [{ trigger: "blur", validator: validateMobileNumber }],
      },

      editEduType: 1,
      dlgEduVisible: false,
      educationForm: {},
      confirmLoading: false,
      educationRules: {
        schoolId: [
          { required: true, trigger: "blur", validator: validateSchoolId },
        ],
        educationType: [
          { required: true, trigger: "blur", validator: validateEducationType },
        ],
        studentCode: [
          { required: true, trigger: "blur", validator: validateStudentCode },
        ],
      },

      schoolTypeOptions: [],
      //民族
      nationOptions: [
        { value: "汉族" },
        { value: "壮族" },
        { value: "满族" },
        { value: "回族" },
        { value: "苗族" },
        { value: "维吾尔族" },
        { value: "彝族" },
        { value: "藏族" },
        { value: "土家族" },
        { value: "蒙古族" },
        { value: "布依族" },
        { value: "侗族" },
        { value: "瑶族" },
        { value: "朝鲜族" },
        { value: "白族" },
        { value: "哈尼族" },
        { value: "哈萨克族" },
        { value: "黎族" },
        { value: "傣族" },
        { value: "畲族" },
        { value: "傈僳族" },
        { value: "仡佬族" },
        { value: "东乡族" },
        { value: "高山族" },
        { value: "拉祜族" },
        { value: "水族" },
        { value: "佤族" },
        { value: "纳西族" },
        { value: "羌族" },
        { value: "土族" },
        { value: "仫佬族" },
        { value: "锡伯族" },
        { value: "柯尔克孜族" },
        { value: "达斡尔族" },
        { value: "景颇族" },
        { value: "毛南族" },
        { value: "撒拉族" },
        { value: "布朗族" },
        { value: "塔吉克族" },
        { value: "阿昌族" },
        { value: "普米族" },
        { value: "鄂温克族" },
        { value: "怒族" },
        { value: "京族" },
        { value: "基诺族" },
        { value: "德昂族" },
        { value: "保安族" },
        { value: "俄罗斯族" },
        { value: "裕固族" },
        { value: "乌孜别克族" },
        { value: "门巴族" },
        { value: "鄂伦春族" },
        { value: "独龙族" },
        { value: "塔塔尔族" },
        { value: "赫哲族" },
        { value: "珞巴族" },
      ],
      //学历
      educationTypeOptions: [
        {
          value: "普通本科",
          label: "普通本科",
        },
        {
          value: "普通专科",
          label: "普通专科",
        },
        {
          value: "成人本科",
          label: "成人本科",
        },
        {
          value: "成人专科",
          label: "成人专科",
        },
        {
          value: "硕士",
          label: "硕士",
        },
        {
          value: "博士",
          label: "博士",
        },
      ],
      //学校
      schools: [],
      value: [],
      allSchools: [],
      loading: false,

      //批量导入
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      dlgImportVisible: false,
      fileForm: {
        name: "",
        file: null,
      },
      hasFile: false,
      fileList: [],
      importRules: {
        file: [{ required: true, message: "请选择上传文件", trigger: "blur" }],
      },

      //学生详情
      sysStudentInfoData: [], //采集数据信息
      orderInfoForm: { orderNo: "", collectedTime: "", shotTypeCode: "" },
      noneImgUrl: "../temp/none_img.jpg",
      photoImg: "../temp/none_img.jpg",

      //按钮权限
      searchButton: false,
      addButton: false,
      updateButton: false,
      deleteButton: false,
      viewButton: false,
      queryStuLog: false,
      stuListLog: [],
      showStuLogs: false,
      educationButton: false,

      eduListLogs: [],
      showEdu: false,
    };
  },
  created() {
    this.queryButton();
    this.querySchools();
    this.querySchoolType();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 8;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "queryStuLog") {
            this.queryStuLog = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
          if (button.code === "education") {
            this.educationButton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    querySchoolType() {
      getDict("school_type").then((resp) => {
        this.schoolTypeOptions = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableStudentLoading = true;
      const data = {
        name: this.searchName,
        mobileNumber: this.searchTel,
        idNumber: this.searchIdNumber,
        schoolId: this.searchSchoolId,
        cjmXm: this.searchCjmXm,
        cjmZjhm: this.searchcjmZjhm,
        cjmn: this.searchCjmn,
        studentCode: this.studentCode,
      };

      queryStudent(this.currentPage, this.pageSize, data).then((resp) => {
        this.studentList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableStudentLoading = false;
      });
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchName = "";
      this.searchTel = "";
      this.searchIdNumber = "";
      this.searchSchoolId = null;
      this.currentPage = 1;
      this.query();
    },
    handleAdd() {
      this.studentForm = { status: 1 };
      if (this.$refs.studentForm !== undefined) {
        this.$refs.studentForm.resetFields();
      }
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    handleImport() {
      this.dlgImportVisible = true;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的项不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }
          deleteStudent(data)
            .then((resp) => {
              if (resp.code === "0") {
                this.$notify({
                  title: "删除成功",
                  message: resp.message,
                  type: "success",
                });
                this.currentPage = 1;
                this.query();
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    add() {
      this.$refs.studentForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.studentForm;
          addStudent(data)
            .then((resp) => {
              this.$notify({
                title: "新增成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    handleStuLogView(data) {
      getStudentLogsList(data.id).then((res) => {
        if (res.code == 0) {
          this.stuListLog = res.data;
          this.showStuLogs = true;
        }
      });
    },
    handleView(id) {
      if (this.$refs.studentInfo != undefined) {
        this.$refs.studentInfo.initData(id)
      }
    
      
    },
    handleUpdate(id) {
      const data = id;
      getStudentForm(data)
        .then((resp) => {
          this.studentForm = resp.data;
          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    update() {
      this.$refs.studentForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.studentForm;

          updateStudent(data)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    //学历管理
    handleAddEducation() {
      this.initEducationForm();
      this.editEduType = 1;
    },
    handleUpdateEducation(id) {
      getEducation(id)
        .then((resp) => {
          this.educationForm = resp.data;
          this.editEduType = 2;
        })
        .catch((error) => {});
    },
    // 解绑学历
    handleUnbinding(id) {
      this.$confirm("此操作将解绑学历, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        unbind(id)
          .then((resp) => {
            if (resp.code === "0") {
              this.$notify({
                title: "解绑成功",
                message: resp.message,
                type: "success",
              });
              this.queryEducation(this.studentId);
            } else {
              this.$notify({
                title: "警告",
                message: resp.message,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.$notify({
              title: "未知错误",
              message: error,
              type: "warning",
            });
          });
      });
    },
    handleEduShow(id) {
      getStudentEduLogsList(id).then((res) => {
        if (res.code == 0) {
          this.eduListLogs = res.data;
          this.showEdu = true;
        }
      });
    },
    handleDeleteEducation(id) {
      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          data.push(id);
          deleteEducation(data)
            .then((resp) => {
              if (resp.code === "0") {
                this.$notify({
                  title: "删除成功",
                  message: resp.message,
                  type: "success",
                });
                this.queryEducation(this.studentId);
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    initEducationForm() {
      this.educationForm = {
        studentId: this.studentId,
        shotType: 1,
        status: 1,
      };
    },
    queryEducation(studentId) {
      getEducations(studentId)
        .then((resp) => {
          this.educationList = resp.data;
          this.editEduType = 1;
          this.dlgEduVisible = true;
          this.initEducationForm();
        })
        .catch((error) => {});
    },
    handleEducation(id) {
      const data = id;
      this.studentId = id;
      this.queryEducation(data);
      if (this.$refs.educationForm !== undefined) {
        this.$refs.educationForm.resetFields();
      }
    },
    handleSaveTime() {
      this.$refs.educationForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.educationForm;
          if (this.editEduType === 1) {
            //新增学籍信息
            addEducation(data)
              .then((resp) => {
                this.$notify({
                  title: "新增成功",
                  message: resp.message,
                  type: "success",
                });
                this.confirmLoading = false;
                this.queryEducation(this.studentId);
              })
              .catch((error) => {
                this.confirmLoading = false;
              });
          } else {
            //修改
            //新增时段
            updateEducation(data)
              .then((resp) => {
                this.$notify({
                  title: "修改成功",
                  message: resp.message,
                  type: "success",
                });
                this.confirmLoading = false;
                this.queryEducation(this.studentId);
              })
              .catch((error) => {
                this.confirmLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    //批量导入
    submitUpload() {
      //this.$refs.upload.submit();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles(); // 清除上传的文件
      }
      this.fileList = [];

      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          let dataPar = this.fileForm;
          const file = dataPar.file;
          const size = file.size / 1024 / 1024;
          if (size > 10) {
            this.$notify.warning({
              title: "警告",
              message: "大小必须小于10M",
            });
            return;
          }

          let fdParams = new FormData();
          fdParams.append("name", dataPar.name);
          fdParams.append("file", dataPar.file.raw);
          importStudent(fdParams).then((resp) => {
            if (resp.data.code == 0) {
              this.importMsg = resp.data.msg;
              this.importSatus = "success";
            } else {
              this.importMsg = resp.data.msg;
              this.importSatus = "error";
            }
            this.msgVisible = false;
            this.query();
          });
        }
      });
    },
 
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.fileForm.file = null;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.fileForm.file = file;
      // 选择完直接上传
      this.submitUpload();
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style>
.divForm {
  margin-top: 15px;
}

.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 35px;
  line-height: 35px;
  vertical-align: bottom;
}
.rowGray .cell {
  background: #f2f2f2;
}
.gray {
  background-color: #f2f2f2;
}

.divice {
  width: 1200px;
  height: 180px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}

.photo {
  width: 120px;
  height: 160px;
}

</style>
