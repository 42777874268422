<template>
  <div>
    <el-dialog title="查看学生信息" :visible.sync="visible" width="900px">
      <table class="bigtable">
        <div
          :style="
            sysStudentInfoData.length != 0
              ? 'height: 450px; overflow-y: auto'
              : 'height: 350px; overflow-y: auto'
          "
        >
          <template v-if="sysStudentInfoData.length != 0">
            <div
              style="margin-bottom: 20px"
              v-for="item in sysStudentInfoData"
              :key="item.index"
            >
              <div class="tip" style="background: none">
                采集信息 {{ toChinese(item.orderInfo.num || "0") }}
              </div>
              <tbody>
                <tr class="cell">
                  <th>姓名</th>
                  <td>
                    {{ execDecrypt(item.studentInfo.name, "name") || "-" }}
                  </td>
                  <th>证件号码</th>
                  <td>
                    {{ execDecrypt(item.studentInfo.idNumber, "Idcard") || "-" }}
                  </td>
                  <td rowspan="6" style="text-align: center; width: 140px">
                    <el-image :src="item.orderInfo.pathCollectedPhoto" class="photo">
                      <div slot="placeholder" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div>
                      <el-link
                        type="primary"
                        style="margin-top: 10px"
                        v-if="item.orderInfo.isPhoto"
                        @click="
                          clickDown(
                            item.orderInfo.pathCollectedPhoto,
                            item.studentInfo,
                            item.educationalInfo
                          )
                        "
                        >照片下载</el-link
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>学校名称</th>
                  <td>{{ item.educationalInfo.schoolName || "-" }}</td>
                  <th>院校代码</th>
                  <td>{{ item.educationalInfo.schoolCode || "-" }}</td>
                </tr>
                <tr class="cell">
                  <th>学校类别</th>
                  <td>{{ item.educationalInfo.schoolTypeName || "-" }}</td>
                  <th>学历类别</th>
                  <td>{{ item.educationalInfo.xllb || "-" }}</td>
                </tr>

                <tr>
                  <th>班级</th>
                  <td>{{ item.educationalInfo.classes || "-" }}</td>
                  <th>分院</th>
                  <td>{{ item.educationalInfo.fy || "-" }}</td>
                </tr>
                <tr class="cell">
                  <th>专业</th>
                  <td>{{ item.educationalInfo.major || "-" }}</td>
                  <th>学号</th>
                  <td>{{ item.educationalInfo.studentCode || "-" }}</td>
                </tr>
                <tr>
                  <th>学历层次</th>
                  <td>{{ item.educationalInfo.cc || "-" }}</td>
                  <th>毕业时间</th>
                  <td>
                    {{ formDateTimeFormat(item.educationalInfo.graduationYear)|| "-" }}
                  </td>
                </tr>
                <tr class="cell">
                  <th>手机号</th>
                  <td >
                    {{ item.studentInfo.wxNumber || "-" }}
                  </td>
                  <th>拍摄序号</th>
                  <td >
                    {{ item.orderInfo.batchIndex || "-" }}
                  </td>
                </tr>
                <tr>
                  <th>采集类型</th>
                  <td>{{ item.orderInfo.shotTypeName || "-" }}</td>
                  <th>采集时间</th>
                  <td colspan="4">
                    {{ formDateTimeFormat(item.orderInfo.collectedTime) || "-" }}
                  </td>
                </tr>
                <tr class="cell">
                  <th>采集码</th>
                  <td>
                    {{ item.orderInfo.cjmn || "-"
                    }}<span
                      v-if="item.orderInfo.cjmn"
                      style="margin-left: 5px; cursor: pointer"
                      @click="generateCjm(item.studentInfo, item.educationalInfo)"
                      ><i class="el-icon-camera-solid"></i
                    ></span>
                  </td>
                  <th>上传批次</th>
                  <td colspan="4">{{ item.orderInfo.batchName || "-" }}</td>
                </tr>
                <template v-if="item.orderInfo.shotTypeCode == 1">
                  <tr>
                    <th>收件人</th>
                    <td>{{ item.orderInfo.contactPerson || "-" }}</td>
                    <th>收件电话</th>
                    <td colspan="4">{{ item.orderInfo.contactTell || "-" }}</td>
                  </tr>
                  <tr class="cell">
                    <th>收件地址</th>
                    <td colspan="12">
                      {{ item.orderInfo.regionProvince || ""
                      }}{{ item.orderInfo.regionCity || ""
                      }}{{ item.orderInfo.regionCounty || ""
                      }}{{ item.orderInfo.mailingAdress || "" }}
                    </td>
                  </tr>
                  <tr>
                    <th>快递单号</th>
                    <td>{{ item.orderInfo.mailNo || "-" }}</td>
                    <th>邮寄时间</th>
                    <td colspan="4">{{ item.orderInfo.mailTime || "-" }}</td>
                  </tr>
                  <tr class="cell">
                    <th>邮寄状态</th>
                    <td colspan="12">
                      <label v-if="item.orderInfo.mailStatus">
                        <span v-show="item.orderInfo.mailStatus == 1"
                          >待生成邮寄单号</span
                        >
                        <span v-show="item.orderInfo.mailStatus == 2"
                          >正在生成邮寄单号</span
                        >
                        <span v-show="item.orderInfo.mailStatus == 3">待打印面单</span>
                        <span v-show="item.orderInfo.mailStatus == 4">待确认邮寄</span>
                        <span v-show="item.orderInfo.mailStatus == 5">正在提交邮寄</span>
                        <span v-show="item.orderInfo.mailStatus == 6">已邮寄</span></label
                      >
                      <label v-else>
                        <span>-</span>
                      </label>
                    </td>
                  </tr>
                </template>
              </tbody>
            </div></template
          >
          <template v-else>
            <div style="width: 260px; margin: 0 auto; text-align: center">
              <el-empty description="暂无采集记录"></el-empty>
            </div>
          </template>
        </div>
      </table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="visible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 采集码生成 -->
    <qrCodeBox ref="qrCodeFrom" />
  </div>
</template>
<script>
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { dateFormat } from "@/utils/date";
import { sysStudentInfo } from "@/api/student";
import { getToken } from "@/utils/auth";
import axios from "axios";
import qrCodeBox from "@/components/qrCodeBox.vue";

export default {
  name: "studentInfoFrom",
  components: {
    qrCodeBox, //查看详情
  },
  props: {
    width: {
      type: String,
      default: "1000px",
    },
  },
  data() {
    return {
      visible: false,
      //学生详情
      sysStudentInfoData: [], //采集数据信息
      noneImgUrl: "../temp/none_img.jpg",
      photoImg: "../temp/none_img.jpg",
    };
  },
  methods: {
    formDateTimeFormat(dateValue, format = "YYYY-mm-dd") {
      if (dateValue != null) {
        return dateFormat(format, new Date(dateValue));
      }
    },
    toChinese(val) {
      let chin_list = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]; //所有的数值对应的汉字
      let sn = parseInt(val) + 1; //这里由于我的后台是从0开始排序
      if (sn <= 10) {
        return chin_list[sn - 1];
      } else if (sn <= 100) {
        if (sn < 20) return "十" + chin_list[(sn % 10) - 1];
        if (sn % 10 == 0) return chin_list[Math.floor(sn / 10) - 1] + "十";
        else return chin_list[Math.floor(sn / 10) - 1] + "十" + chin_list[(sn % 10) - 1];
      }
    },
    clickDown(pathCollectedPhoto, studentInfo, educationalInfo) {
      const a = document.createElement("a");
      a.href = pathCollectedPhoto;
      //   console.log(a.href);
      let str =
        // this.studentForm.idNumber +
        // "-" +
        studentInfo.name +
        "-" +
        educationalInfo.studentCode +
        "-" +
        educationalInfo.schoolName +
        "-" +
        educationalInfo.schoolId +
        ".jpg";
      a.download = str; // 下载文件的名字
      document.body.appendChild(a);
      a.click();
      //在资源下载完成后 清除 占用的缓存资源
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "name") {
        return formatName(str);
      }
      if (mod == "ph") {
        return formatPh(str);
      }
      if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
    getPhoto(fileid) {
      const url =
        "/api/csias/file/download/" +
        fileid.pathCollectedPhoto +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            fileid.pathCollectedPhoto = window.URL.createObjectURL(imgContent);
            fileid.isPhoto = true;
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到照片信息",
          });
        }
      );
    },
    initData(orderId) {
      this.sysStudentInfoData = [];
      this.photoImg = this.noneImgUrl + "?timestamp=" + new Date().getTime();
      //查采集信息
      sysStudentInfo(orderId)
        .then((resp) => {
          if (resp.data) {
            this.visible = true;
            this.sysStudentInfoData = resp.data;
            let url = [];
            let num = 0;
            resp.data.map((item) => {
              if (item.orderInfo) {
                url.push(item.orderInfo);
                item.orderInfo.num = num++;
              }
            });
            url.map((items) => {
              if (items.pathCollectedPhoto === null || items.pathCollectedPhoto === "") {
                items.pathCollectedPhoto =
                  this.noneImgUrl + "?timestamp=" + new Date().getTime();
                items.isPhoto = false;
              } else {
                items.pathCollectedPhoto = this.getPhoto(items);
              }
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },

    // 采集码弹窗
    generateCjm(studentInfo, info) {
      if (this.$refs.qrCodeFrom != undefined) {
        info.name=studentInfo.name
        info.idNumber=studentInfo.idNumber
        this.$refs.qrCodeFrom.initDatas(info.cjmString,info.cjmPath,info);
      }
    },
  },
};
</script>
<style scoped>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}
.image-box {
  width: 120px;
  height: 160px;
  margin: auto;
  position: relative;
}
.image-box.line .photo:before {
  content: " ";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #e13333;
  left: 50%;
}
.image-box.line .drag-list {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 30px;
  cursor: all-scroll;
}
.image-box.line .drag-list::before {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #e13333;
  top: 50%;
  left: 0;
}
.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}
.tbody {
  margin-bottom: 20px;
}
.bigtable {
  width: 850px;
  margin: 0 auto;

  /* text-align: center; */
}

table {
  /* 合并边框 */
  border-collapse: collapse;
}

/* 设置单元格的样式 */
td {
  border: 1px solid #e8e8e8;
  width: 360px;
  padding-left: 5px;
}

th {
  border: 1px solid #e8e8e8;
  width: 220px;
  height: 35px !important;
  text-align: left;
  padding-left: 5px;
}

.cell {
  background: #f2f2f2;
}
</style>
